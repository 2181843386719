
import {
  Component,
  Vue,
} from 'vue-property-decorator';

// Lazy loading imports
const UsernamePassword = () => import(
  /* webpackChunkName: "login-username-password" */
  /* webpackPrefetch: false */
  '@/components/entry/registration/UsernamePassword.vue'
);
const WebAuthn = () => import(
  /* webpackChunkName: "login-web-authn" */
  /* webpackPrefetch: false */
  '@/components/entry/registration/WebAuthn.vue'
);

type Type = 'UsernamePassword'|'WebAuthn'

@Component({
  name: 'Registration',
  components: {
    UsernamePassword,
    WebAuthn,
  },
})
export default class Registration extends Vue {
  private component: { type: string, data?: string } | null = null;

  private match = (name: string): Type => {
    switch (name) {
      case 'basic':
        return 'UsernamePassword';
      case 'webAuthN':
        return 'WebAuthn';
      default:
        throw Error(`No component available for ${name}`);
    }
  };

  mounted(): void {
    this.fetchData();
  }

  // todo implement this function
  private fetchData() {
    const response = 'webAuthN';
    this.handleData(response);
  }

  // todo implement this function
  private handleData(data: string) {
    this.component = {
      type: this.match(data),
      data,
    };
  }
}
